// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyright {
    text-align: left !important;
    line-height: 40px;
    color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/footer/footer.component.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".copyright {\r\n    text-align: left !important;\r\n    line-height: 40px;\r\n    color: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
